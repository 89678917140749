import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    var element = document.getElementById("site-nav");
    element.classList.add("nav-dark");
    return () => {
      element.classList.remove("nav-dark");
    }
  }, [])

  return (
    <div>
      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">About Us</h1>

            <ul className="bradcurmed">
              <li><NavLink to="/">Home</NavLink></li>
              <li>About Us</li>
            </ul>
          </div>
        </div>


        <svg className="circle" data-parallax='{"x" : -200}' xmlns="http://www.w3.org/2000/svg" width="950px" height="950px">
          <path fill-rule="evenodd" stroke="rgb(250, 112, 112)" stroke-width="100px" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.051" fill="none" d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z" />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section class="featured-four-ab">
        <div class="container">
          <div class="section-title text-center">
            <h3 class="sub-title wow pixFadeUp">Our service</h3>
            <h2 class="title wow pixFadeUp" data-wow-delay="0.3s">Why you choose Our plugin</h2>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="saaspik-icon-box-wrapper style-four wow pixFadeLeft" data-wow-delay="0.5s">
                <div class="saaspik-icon-box-icon">
                  <img src="media/feature/20.png" alt="" />
                </div>
                <div class="pixsass-icon-box-content">
                  <h3 class="pixsass-icon-box-title"><a href="#">New sharing made for people</a></h3>
                  <p>
                    The full monty twit cracking goal Jeffrey lurgy chinwag bobby vagabond David, I don't want no agro what a load.!
                  </p>

                  <a href="#" class="more-btn"><i class="ei ei-arrow_right"></i></a>

                  <svg class="layer" xmlns="http://www.w3.org/2000/svg" width="370px" height="270px">
                    <path fill-rule="evenodd" fill="rgb(253, 248, 248)" d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="saaspik-icon-box-wrapper style-four wow pixFadeLeft" data-wow-delay="0.6s">
                <div class="saaspik-icon-box-icon">
                  <img src="media/feature/19.png" alt="" />
                </div>
                <div class="pixsass-icon-box-content">
                  <h3 class="pixsass-icon-box-title"><a href="#">One integrated solution Manage</a></h3>

                  <p>
                    The full monty twit cracking goal Jeffrey lurgy chinwag bobby vagabond David, I don't want no agro what a load.!
                  </p>

                  <a href="#" class="more-btn"><i class="ei ei-arrow_right"></i></a>
                </div>

                <svg class="layer" xmlns="http://www.w3.org/2000/svg" width="370px" height="270px">
                  <path fill-rule="evenodd" fill="rgb(253, 248, 248)" d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z" />
                </svg>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="saaspik-icon-box-wrapper style-four wow pixFadeLeft" data-wow-delay="0.9s">
                <div class="saaspik-icon-box-icon">
                  <img src="media/feature/18.png" alt="" />
                </div>
                <div class="pixsass-icon-box-content">
                  <h3 class="pixsass-icon-box-title"><a href="#">Thousand of features and Custom option.</a></h3>
                  <p>
                    The full monty twit cracking goal Jeffrey lurgy chinwag bobby vagabond David, I don't want no agro what a load.!
                  </p>

                  <a href="#" class="more-btn"><i class="ei ei-arrow_right"></i></a>
                </div>

                <svg class="layer" xmlns="http://www.w3.org/2000/svg" width="370px" height="270px">
                  <path fill-rule="evenodd" fill="rgb(253, 248, 248)" d="M-0.000,269.999 L-0.000,-0.001 L370.000,-0.001 C370.000,-0.001 347.889,107.879 188.862,112.181 C35.160,116.338 -0.000,269.999 -0.000,269.999 Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="countup">
        <div class="bg-map" data-bg-image="media/background/map2.png">

        </div>
        <div class="container">
          <div class="section-title text-center">
            <h3 class="sub-title wow pixFadeUp">Fun Facts</h3>
            <h2 class="title wow pixFadeUp" data-wow-delay="0.2s">
              We Always try to Understand<br />
              Users expectation
            </h2>
          </div>


          <div class="countup-wrapper">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact wow pixFadeUp" data-wow-delay="0.3s">
                  <div class="counter">
                    <h4 class="count" data-counter="14">14 </h4>
                    <span>K+</span>
                  </div>


                  <p>Total Download</p>
                </div>

              </div>


              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact color-two wow pixFadeUp" data-wow-delay="0.5s">
                  <div class="counter">
                    <h4 class="count" data-counter="13">13 </h4>
                    <span>M+</span>
                  </div>

                  <p>Total Download</p>
                </div>

              </div>


              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact color-three wow pixFadeUp" data-wow-delay="0.7s">
                  <div class="counter">
                    <h4 class="count" data-counter="244">244 </h4>
                    <span>+</span>
                  </div>

                  <p>Total Download</p>
                </div>

              </div>


              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact color-four wow pixFadeUp" data-wow-delay="0.9s">
                  <div class="counter">
                    <h4 class="count" data-counter="53">53 </h4>
                    <span>M+</span>
                  </div>

                  <p>Total Download</p>
                </div>

              </div>

            </div>

          </div>


          <div class="button-container text-center wow pixFadeUp" data-wow-delay="0.3s">
            <a href="#" class="pix-btn btn-outline">See All Review</a>
          </div>

        </div>

      </section>

      <section class="call-to-action">
        <div class="overlay-bg"><img src="media/background/ellipse.png" alt="bg" /></div>
        <div class="container">
          <div class="action-content text-center wow pixFadeUp">
            <h2 class="title">
              We are optimists who<br />
              love to work together
            </h2>

            <p>
              Spiffing good time nice one mush ummm I'm telling down the pub spend<br />
              a penny only a quid such a fibber tomfoolery, on your bike.
            </p>

            <a href="#" class="pix-btn btn-light">Free Consultation</a>
          </div>
        </div>

        <div class="scroll-circle">
          <img src="media/background/circle13.png" data-parallax='{"y" : -130}' alt="circle" />
        </div>
      </section>
    </div>
  )
}

export default About