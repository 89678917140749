import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    var element = document.getElementById("site-nav");
    element.classList.add("nav-dark");
    return () => {
      element.classList.remove("nav-dark");
    }
  }, [])

  return (
    <div>
      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Services</h1>

            <ul className="bradcurmed">
              <li><NavLink to="/" >Home</NavLink></li>
              <li>Services</li>
            </ul>
          </div>
        </div>

        <svg className="circle" data-parallax='{"x" : -200}' xmlns="http://www.w3.org/2000/svg" width="950px" height="950px">
          <path fill-rule="evenodd" stroke="rgb(250, 112, 112)" stroke-width="100px" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.051" fill="none" d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z" />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>


      <section className="featured-two-service">
        <div className="container">
          <div className="section-title text-center">
            <h3 className="sub-title wow pixFadeUp">Our service</h3>
            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">Why you choose Our plugin</h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="saaspik-icon-box-wrapper style-two wow pixFadeUp" data-wow-delay="0.3s">
                <div className="saaspik-icon-box-icon">
                  <img src="media/feature/6.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title"><a href="#">App Development</a></h3>
                  <p>
                    The full monty do one nancy boy<br />
                    say gutted mate cockup Why at <br />
                    public school.!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="saaspik-icon-box-wrapper style-two wow pixFadeUp" data-wow-delay="0.5s">
                <div className="saaspik-icon-box-icon">
                  <img src="media/feature/7.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title"><a href="#">Customization</a></h3>

                  <p>
                    The full monty do one nancy boy<br />
                    say gutted mate cockup Why at <br />
                    public school.!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="saaspik-icon-box-wrapper style-two wow pixFadeUp" data-wow-delay="0.7s">
                <div className="saaspik-icon-box-icon">
                  <img src="media/feature/8.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title"><a href="#">Multiple Managers</a></h3>
                  <p>
                    The full monty do one nancy boy<br />
                    say gutted mate cockup Why at <br />
                    public school.!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="saaspik-icon-box-wrapper style-two wow pixFadeUp" data-wow-delay="0.9s">
                <div className="saaspik-icon-box-icon">
                  <img src="media/feature/9.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title"><a href="#">Cloud Data Saved</a></h3>
                  <p>
                    The full monty do one nancy boy<br />
                    say gutted mate cockup Why at <br />
                    public school.!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="saaspik-icon-box-wrapper style-two wow pixFadeUp" data-wow-delay="1s">
                <div className="saaspik-icon-box-icon">
                  <img src="media/feature/10.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title"><a href="#">UI/UX Branding</a></h3>
                  <p>
                    The full monty do one nancy boy<br />
                    say gutted mate cockup Why at <br />
                    public school.!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="saaspik-icon-box-wrapper style-two wow pixFadeUp" data-wow-delay="1.2s">
                <div className="saaspik-icon-box-icon">
                  <img src="media/feature/11.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title"><a href="#">Customer Support</a></h3>
                  <p>
                    The full monty do one nancy boy<br />
                    say gutted mate cockup Why at <br />
                    public school.!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="newsletter-two" id="newsletter">
        <div class="container">

          <div class="newsletter-content-two text-center wow pixFadeUp">
            <h2 class="title">
              Comprueba ahora como<br /> pixsaas trabaja para tu asesoria
            </h2>

            <p class="description">
              Solicita una demo personalizada para descubrir como pixsaas puede ayudarte a mejorar la<br />
              gestion de los clientes de tu despacho profesional.
            </p>
          </div>

          <form action="php/subscribe.php" method="post" class="newsletter-form-two wow pixFadeUp" data-pixsaas="newsletter-subscribe">
            <div class="newsletter-inner">

              <input type="email" name="email" class="form-control" id="newsletter-form-email" placeholder="Enter your Email" required />
              <button type="submit" name="submit" id="newsletter-submit" class="newsletter-submit">
                <span class="btn-text">Subscribe Now!</span>
                <i class="fas fa-spinner fa-spin"></i>
              </button>
              <input type="hidden" name="recaptcha_response" id="recaptchaResponse" />
            </div>

            <div class="clearfix"></div>
            <div class="form-result alert">
              <div class="content"></div>
            </div>
          </form>

        </div>

        <div class="scroll-circle wow pixFadeUp">
          <img src="media/background/circle12.png" data-parallax='{"y" : -230}' alt="circle6" />
        </div>
      </section>
    </div>
  )
}

export default Services