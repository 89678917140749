import React, { useEffect } from 'react'

const Home = () => {

	return (
		<>
			<section className="banner banner-one">
				<div className="circle-shape" data-parallax='{"y" : 230}'><img src="media/banner/circle-shape.png" alt="circle" /></div>
				<div className="container">
					<div className="banner-content-wrap">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<div className="banner-content">
									<h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
										Saas <span>Software <br />
											Landing</span> is the<br />
										Best Ever
									</h1>

									<p className="description wow pixFadeUp" data-wow-delay="0.5s">
										Why I say old chap that is spiffing bits and bobs chimney<br />
										pot cracking goal bamboozled.!
									</p>

									<a href="#" className="pxs-btn banner-btn wow pixFadeUp" data-wow-delay="0.6s">Get Started</a>
								</div>
							</div>

							<div className="col-lg-6">
								<div className="promo-mockup wow pixFadeLeft">
									<img src="media/banner/macbook.png" alt="mpckup" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-shape">
					<img src="media/banner/shape-bg.png" alt="" />
				</div>
			</section>

			<section className="featured">
				<div className="container">
					<div className="section-title text-center wow pixFade">
						<h3 className="sub-title">Working Process</h3>
						<h2 className="title">The only app you will need</h2>
					</div>

					<div className="row">
						<div className="col-md-4">
							<div className="saaspik-icon-box-wrapper style-one wow pixFadeLeft" data-wow-delay="0.3s">
								<div className="saaspik-icon-box-icon">
									<img src="media/feature/1.png" alt="" />
								</div>
								<div className="pixsass-icon-box-content">
									<h3 className="pixsass-icon-box-title"><a href="#">New sharing made for<br /> people</a></h3>
								</div>
							</div>
						</div>

						<div className="col-md-4">
							<div className="saaspik-icon-box-wrapper style-one wow pixFadeLeft" data-wow-delay="0.5s">
								<div className="saaspik-icon-box-icon">
									<img src="media/feature/2.png" alt="" />
								</div>
								<div className="pixsass-icon-box-content">
									<h3 className="pixsass-icon-box-title"><a href="#">One integrated solution <br />Manage</a></h3>
								</div>
							</div>
						</div>

						<div className="col-md-4">
							<div className="saaspik-icon-box-wrapper style-one wow pixFadeLeft" data-wow-delay="0.7s">
								<div className="saaspik-icon-box-icon">
									<img src="media/feature/3.png" alt="" />
								</div>
								<div className="pixsass-icon-box-content">
									<h3 className="pixsass-icon-box-title"><a href="#">Thousand of features and <br />Custom option.</a></h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Home