import {
	createBrowserRouter,
} from "react-router-dom";
import Layout from "./components/common/Layout";
import Home from "./pages/home";
import About from "./pages/about";
import ContactUs from "./pages/contact-us";
import Services from "./pages/services";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<Layout>
				<Home />
			</Layout>
		),
	},
	{
		path: "/about",
		element: (
			<Layout>
				<About />
			</Layout>
		),
	},
	{
		path: "/contact-us",
		element: (
			<Layout>
				<ContactUs />
			</Layout>
		),
	},
	{
		path: "/services",
		element: (
			<Layout>
				<Services />
			</Layout>
		),
	},
]);


export default router