import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="footer-inner wow pixFadeUp">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="widget footer-widget">
                  <h3 className="widget-title">Company</h3>

                  <ul className="footer-menu">
                    <li><a href="#">Features</a></li>
                    <li><a href="#">Dashboard & Tool</a></li>
                    <li><a href="#">Our Portfolio</a></li>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Get In Touch</a></li>
                  </ul>
                </div>

              </div>


              <div className="col-lg-3 col-md-6">
                <div className="widget footer-widget">
                  <h3 className="widget-title">Services</h3>

                  <ul className="footer-menu">
                    <li><a href="#">Features</a></li>
                    <li><a href="#">Dashboard & Tool</a></li>
                    <li><a href="#">Our Portfolio</a></li>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Get In Touch</a></li>
                  </ul>
                </div>

              </div>


              <div className="col-lg-3 col-md-6">
                <div className="widget footer-widget">
                  <h3 className="widget-title">Digital Experience</h3>

                  <ul className="footer-menu">
                    <li><a href="#">Features</a></li>
                    <li><a href="#">Dashboard & Tool</a></li>
                    <li><a href="#">Our Portfolio</a></li>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Get In Touch</a></li>
                  </ul>
                </div>

              </div>


              <div className="col-lg-3 col-md-6">
                <div className="widget footer-widget">
                  <h3 className="widget-title">Our Address</h3>

                  <p>
                    Lavaca Street, Suite 24, Road Apt New York, USA
                  </p>

                  <ul className="footer-social-link">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                  </ul>
                </div>

              </div>

            </div>


          </div>

          <div className="site-info">
            <div className="copyright">
              <p>© 2023 All Rights Reserved by <NavLink to="/" target="_blank">Code 'n Care</NavLink></p>
            </div>

            <ul className="site-info-menu">
              <li><a href="#">Privacy & Policy.</a></li>
              <li><a href="#">Faq.</a></li>
              <li><a href="#">Terms.</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer