import React, { useEffect, useState } from 'react'
import {
  NavLink
} from 'react-router-dom'

const Header = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  useEffect(() => {
    var element = document.getElementsByTagName("html")[0];

    if (isOpenSidebar) {
      element.classList.add("no-scroll");
      element.classList.add("sidebar-open");
    } else {
      element.classList.remove("no-scroll");
      element.classList.remove("sidebar-open");
    }
  }, [isOpenSidebar])


  return (
    <header className="site-header header_trans-fixed" data-top="992">
      <div className="container">
        <div className={`header-inner ${isOpenSidebar ? "no-scroll sidebar-open" : ""}`}>
          <div className="toggle-menu" onClick={() => setIsOpenSidebar(true)}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <div className="site-mobile-logo">
            <NavLink to="/" className="logo">
              <img src="assets/img/main-logo.png" alt="site logo" className="main-logo" />
              {/* <img src="assets/img/main-logo.png" alt="site logo" className="main-logo dark" /> */}
              <img src="assets/img/sticky-logo.png" alt="site logo" className="sticky-logo" style={{ display: "none" }} />
            </NavLink>
          </div>

          <nav className="site-nav" id="site-nav">
            <div className="close-menu" onClick={() => setIsOpenSidebar(false)}>
              <span>Close</span>
              <i className="ei ei-icon_close"></i>
            </div>

            <div className="site-logo">
              <NavLink to="/" className="logo">
                <img src="assets/img/main-logo.png" alt="site logo" className="main-logo" />
                {/* <img src="assets/img/main-logo.png" alt="site logo" className="main-logo dark" /> */}
                <img src="assets/img/sticky-logo.png" alt="site logo" className="sticky-logo" style={{ display: "none" }} />
              </NavLink >
            </div>

            <div className="menu-wrapper" data-top="992">
              <ul className="site-main-menu">
                <li className="menu-item-has-children">
                  <NavLink onClick={() => setIsOpenSidebar(false)} to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setIsOpenSidebar(false)} to="/about">About</NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setIsOpenSidebar(false)} to="/contact-us">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setIsOpenSidebar(false)} to="/services">Services</NavLink>
                </li>
              </ul>
              {/* <div className="nav-right">
                <a href="signup.html" className="nav-btn">Free Sign Up</a>
              </div> */}
            </div>

          </nav>
        </div>
      </div>
    </header >
  )
}

export default Header